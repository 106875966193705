import React from 'react'
import Routes from './routes'
import './styles/reset.scss'
import './styles/main.scss'
import { LoadingProvider, useLoading } from './context/loading/loading.context'
import { Loading } from './components/common'

const App = () => {
  const { loading } = useLoading()

  return (
    <div className="App">
      <LoadingProvider>
        {loading && <Loading title="Carregando..." />}
        <Routes />
      </LoadingProvider>
    </div>
  )
}

export default App
