import React, { useCallback } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { Loading, Button } from '../../common'
import { useLoading } from '../../../context/loading/loading.context'
import { useSendFileSus } from '../../../context/sendFileSus/sendFileSus.context'
import api from '../../../services/api'
import { ButtonSendFile, ExamItem } from '../index'

import './styles.scss'
import 'react-toastify/dist/ReactToastify.css'

type IExamsList = {
  uploadInfo: {
    userUUID: string
    schedulingUUID: string
    type: number
    token: string
  }
}

const ExamsList: React.FC<IExamsList> = ({ uploadInfo }) => {
  const { files, setConcluded } = useSendFileSus()
  const { loading, setLoading } = useLoading()

  const handleExamsSubmit = useCallback(async () => {
    setLoading(true)
    try {
      const data = new FormData()
      files.map((file: any) => {
        data.append('files', file)
      })

      const url = `/schedulings/v2/${uploadInfo?.schedulingUUID}/exams`

      await api.post(url, data)

      setLoading(false)
      toast.success('Os seus exames foram enviados com sucesso!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })

      setConcluded(true)
    } catch (err) {
      setLoading(false)
      toast.error('Não foi possível enviar os seus arquivos.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }, [files, uploadInfo])

  return (
    <div className="wrapper container listExams">
      {loading && <Loading title="Carregando..." />}
      <p style={{ marginTop: 24, marginBottom: 24 }}>
        Os exames que o seu médico vai receber estão aqui:
      </p>
      <div className="listExams--content">
        {files.length > 0 ? (
          files.map((file) => (
            <ExamItem key={file.name} id={file.name} name={file.name} isTrash />
          ))
        ) : (
          <span>Nenhum exame selecionado</span>
        )}

        <ButtonSendFile className="listExams--buttonAdd" text="Adicionar +" />

        <Button
          className="listExams--btnSend"
          text="Enviar"
          disabled={!files}
          style={{ marginTop: 50 }}
          onClick={() => handleExamsSubmit()}
        />
      </div>
      <ToastContainer />
    </div>
  )
}

export { ExamsList }
