/* eslint-disable react/require-default-props */
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { toast, ToastContainer } from 'react-toastify'
import { useSendFileSus } from '../../../context/sendFileSus/sendFileSus.context'

type IButtonSendFile = {
  className?: string
  text: string
}

const ButtonSendFile: React.FC<IButtonSendFile> = ({ className, text }) => {
  const { files, setFiles } = useSendFileSus()

  /* eslint-disable */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles: any) => {
      const maxSize = 4 * 2048 * 2048
      const acceptFiles = [
        'image/jpeg',
        'image/pjpeg',
        'image/png',
        'image/gif',
        'application/pdf',
      ]

      if (acceptedFiles[0].size > maxSize) {
        toast.error('A imagem não pode ser maior que 16Mb!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }

      if (!acceptFiles.includes(acceptedFiles[0].type)) {
        toast.error(
          'Tipo do arquivo inválido, só são aceitos imagens do tipo JPG, JPEG, PNG, GIF.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      }

      const renamedAcceptedFiles = acceptedFiles.map(
        (file: any) =>
          new File(
            [file],
            `starbem_${(Math.random() * 100000000)
              .toFixed(0)
              .toString()}.${file.type.slice(6)}`,
            { type: file.type }
          )
      )

      setFiles([...files, ...renamedAcceptedFiles])
    },
  })
  /* eslint-enable */

  return (
    <div style={{ width: '100%' }} {...getRootProps()}>
      <input
        className="sr-only"
        {...getInputProps()}
        accept="image/*, application/pdf"
      />

      <span className={className} style={{ width: '100%', display: 'block' }}>
        {text}
      </span>

      <ToastContainer />
    </div>
  )
}

export { ButtonSendFile }
