import React from 'react'
import { Button } from '../index'
import './style.scss'

interface Props {
  action: () => void
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Modal = ({ action, isOpen, setIsOpen }: Props) => {
  if (isOpen)
    return (
      <div className="modal-container">
        <div className="modal-content">
          <span>Deseja excluir o exame?</span>
          <div className="modal-buttons">
            <Button text="Sim" onClick={() => action()} />
            <Button
              text="Não"
              outlined
              onClick={() => setIsOpen(false)}
              style={{ marginLeft: 8 }}
            />
          </div>
        </div>
      </div>
    )
  return null
}
export { Modal }
