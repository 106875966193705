/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { toast } from 'react-toastify'

import './style.scss'
import api from 'services/api'
import FilePlus from '../../../assets/icons/filePlusAlt.png'
import Pdf from '../../../assets/icons/pdf.png'
import { useSendFileSus } from '../../../context/sendFileSus/sendFileSus.context'
import { useLoading } from '../../../context/loading/loading.context'
// import { download } from '../../../services/download'
import { Modal } from '../../common'

type IExamItem = {
  id: string
  name: string
  isTrash: boolean
  file?: any
}

const ExamItem: React.FC<IExamItem> = ({ id, name, isTrash, file }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { files, setFiles, token, filesSent, setFilesSent } = useSendFileSus()
  const { setLoading } = useLoading()

  const pdf = file && String(file?.mimetype).includes('pdf') ? 'pdf' : ''

  const handleDelete = async () => {
    setIsOpen(false)
    setLoading(true)
    try {
      await api.delete(`/schedulings/v1/exams/${id}`)
      // eslint-disable-next-line no-underscore-dangle
      setFilesSent(filesSent.filter((item) => item._id !== id))
      toast.success('Arquivo deletado!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
      toast.error('Não foi possível deletar o arquivo.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  return (
    <div className="examItem">
      <Modal
        action={() => handleDelete()}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <div>
        <div className="examItem--imageWrapper">
          {isTrash ? (
            <img src={FilePlus} alt="Ícone de Adicionar Arquivo" />
          ) : (
            file && (
              // <button
              //   type="button"
              //   className="buttonDownload"
              //   onClick={() => download(file, `StarbemExames`)}
              // >
              <img
                src={
                  pdf === 'pdf'
                    ? Pdf
                    : `${process.env.REACT_APP_API_URL}/files/file?name=${file.file_key}`
                }
                alt=" Arquivo"
                className="examImage"
              />
              // </button>
            )
          )}
        </div>
        <span className="examItem--name">{name}</span>
      </div>
      <button
        type="button"
        className="examItem--removeItem"
        onClick={() =>
          !isTrash
            ? setIsOpen(true)
            : setFiles(files.filter((item) => item.name !== id))
        }
      >
        {/* @ts-ignore */}
        <FontAwesomeIcon className="examItem--trashIcon" icon={faTrashAlt} />
      </button>
    </div>
  )
}

export { ExamItem }
