import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Exams from '../containers/Exams'
// import Signature from '../containers/Signature'
import { SendFileSusProvider } from '../context/sendFileSus/sendFileSus.context'

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />
}

const Routes = () => {
  return (
    <Router>
      <Switch>
        {/* <Route path="/" component={Signature} /> */}
        <SendFileSusProvider>
          <PrivateRoute path="/exames/:token" component={Exams} />
        </SendFileSusProvider>
      </Switch>
    </Router>
  )
}

export default Routes
