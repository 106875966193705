import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { Button } from 'components/common'
import './styles.scss'
import { useSendFileSus } from '../../../context/sendFileSus/sendFileSus.context'

const Concluded: React.FC = () => {
  const { setConcluded }: any = useSendFileSus()
  const { setFiles }: any = useSendFileSus()

  return (
    <div className="wrapper container concluded">
      <p style={{ marginTop: 24, marginBottom: 24 }}>
        Os seus exames foram enviados com sucesso
      </p>
      <span className="concluded--icon">
        {/* @ts-ignore */}
        <FontAwesomeIcon color="#1fba5d" icon={faCheckCircle} />
      </span>
      <div className="concluded--content">
        <Button
          className="concluded--btnSend"
          text="Enviar novos arquivos"
          style={{ marginTop: 34 }}
          onClick={() => {
            setConcluded(false)
            setFiles([])
          }}
        />
      </div>
    </div>
  )
}

export { Concluded }
