import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
  },
})

api.interceptors.request.use(
  async (config: any) => {
    return config
  },
  (error: any) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response: any) => {
    return response
  },
  async (error: any) => {
    return Promise.reject(error)
  }
)

export default api
