import React, { useEffect, useState } from 'react'
import './styles.scss'
import { useParams } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { Upload, ExamsList, Header, Concluded } from '../../components/Exams'
import { useSendFileSus } from '../../context/sendFileSus/sendFileSus.context'
import api from '../../services/api'

const Exams = () => {
  const { files, concluded, setFilesSent, setToken } = useSendFileSus()
  const params: any = useParams()
  const token = params?.token

  const [uploadInfo, setUpdateInfo] = useState<any>(null)

  const getExams = async (uuid: string, tokenList: string) => {
    try {
      const response = await api.get(`/schedulings/v1/${uuid}/exams`)

      setFilesSent(response.data.items)
    } catch (err) {
      // @ts-ignore
      console.log(err.response)
    }
  }

  useEffect(() => {
    if (token) {
      const jwtDecoded: any = jwt.decode(token)

      if (jwtDecoded !== null) {
        getExams(jwtDecoded.scheduling_uuid, token)
        setToken(token)
        setUpdateInfo({
          schedulingUUID: jwtDecoded?.scheduling_uuid,
          userUUID: jwtDecoded?.user_uuid,
          type: jwtDecoded?.type,
          token,
        })
      }
    }
  }, [token, concluded])

  return (
    <div className="wrapper container">
      {uploadInfo && uploadInfo?.schedulingUUID && (
        <>
          <Header />
          {concluded && <Concluded />}

          {!concluded && files.length > 0 && uploadInfo && (
            <ExamsList uploadInfo={uploadInfo} />
          )}

          {!concluded && files.length === 0 && <Upload />}
        </>
      )}
    </div>
  )
}

export default Exams
