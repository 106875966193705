import React from 'react'
import Logo from '../../../assets/brand/logo.png'

const Header = () => {
  return (
    <header>
      <img src={Logo} alt="Logo Starbem" width={240} />
    </header>
  )
}

export { Header }
