import React, { useContext, useState, createContext } from 'react'
import {
  SendFileSusProviderProps,
  SendFileSusStateProps,
} from './sendFileSus.types'

const SendFileSusContext = createContext<SendFileSusStateProps>({
  files: [],
  setFiles: () => {},
  concluded: false,
  setConcluded: () => {},
  filesSent: [],
  setFilesSent: () => {},
  token: '',
  setToken: () => {},
})

const SendFileSusProvider: React.FunctionComponent<
  SendFileSusProviderProps
> = ({ children }) => {
  const [files, setFiles] = useState<Array<any>>([])
  const [filesSent, setFilesSent] = useState<Array<any>>([])
  const [concluded, setConcluded] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')

  const value: SendFileSusStateProps = {
    files,
    setFiles,
    concluded,
    setConcluded,
    filesSent,
    setFilesSent,
    token,
    setToken,
  }

  return (
    <SendFileSusContext.Provider value={value}>
      {children}
    </SendFileSusContext.Provider>
  )
}

const useSendFileSus = () => {
  return useContext(SendFileSusContext)
}

export { SendFileSusProvider, useSendFileSus }
