import React, { useState } from 'react'
import { ButtonSendFile, ExamItem } from '../index'
import { Button } from '../../common'
import FilePlus from '../../../assets/icons/filePlus.png'
import './styles.scss'
import { useSendFileSus } from '../../../context/sendFileSus/sendFileSus.context'
import { useLoading } from '../../../context/loading/loading.context'

const Upload = () => {
  const [isSent, setIsSent] = useState(false)
  const { loading } = useLoading()
  const { filesSent } = useSendFileSus()

  return (
    <div className="wrapper container">
      {isSent ? (
        <>
          <p style={{ marginTop: 24 }}>
            Os exames que você enviou estão listados abaixo:
          </p>
          <div className="examsContent">
            {filesSent &&
              filesSent.map((item, index) => {
                return (
                  <ExamItem
                    // eslint-disable-next-line no-underscore-dangle
                    key={item._id}
                    // eslint-disable-next-line no-underscore-dangle
                    id={item._id}
                    name={`Starbem Exames - ${index + 1}`}
                    isTrash={false}
                    file={item}
                  />
                )
              })}
          </div>
          <div className="examsContent">
            <Button
              text="Voltar"
              className="buttonBack"
              loading={loading}
              onClick={() => setIsSent(false)}
            />
          </div>
        </>
      ) : (
        <>
          <p style={{ marginTop: 24 }}>
            Faça o envio dos seus exames para avaliação e acompanhamento correto
            do seu médico.
          </p>
          <div className="examsContent">
            <Button
              text="Visualizar exames enviados"
              outlined
              onClick={() => setIsSent(true)}
            />
          </div>
          <div className="uploadContent">
            <img src={FilePlus} alt="Adicionar arquivo" />
            <span>Arquivo .jpg, .png ou .pdf</span>
            <ButtonSendFile
              className="uploadContent--button"
              text="Anexar exames"
            />
          </div>
        </>
      )}
    </div>
  )
}

export { Upload }
