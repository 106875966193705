/* eslint-disable react/require-default-props */
import React from 'react'
import './style.scss'

type IButton = {
  text: string
  type?: 'primary' | 'secondary' | 'danger' | 'success' | 'custom'
  outlined?: boolean
  disabled?: boolean
  style?: any
  className?: string
  loading?: boolean
}

const Button: React.FC<IButton & React.HTMLProps<HTMLButtonElement>> = ({
  text,
  type = 'primary',
  outlined = false,
  disabled = false,
  style,
  className,
  loading = false,
  onClick,
}) => {
  const getButtonColor = () => {
    switch (type) {
      case 'primary':
        return '#ff3f72'

      case 'secondary':
        return '#FFE4EB'

      case 'danger':
        return '#FF4242'

      case 'success':
        return '#1FBA5D'

      default:
        return 'transparent'
    }
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        ...style,
        backgroundColor: outlined ? 'transparent' : getButtonColor(),
        borderWidth: outlined ? 1 : 0,
        borderColor: getButtonColor(),
        borderStyle: 'solid',
        opacity: disabled ? 0.5 : 1,
      }}
      className={`button ${className}`}
      type="button"
    >
      <div className={`${loading && 'c-loader'}`}>
        <span style={{ color: outlined ? getButtonColor() : '#ffffff' }}>
          {!loading ? text : ''}
        </span>
      </div>
    </button>
  )
}

export { Button }
